import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';
import type { LinkRequestCommand } from '../models/LinkRequestCommand';

@Injectable()
export class LinkRequestsService {
    constructor(private httpService: HttpService) {}

    getAllLinkRequestPendingRequest(): Observable<any> {
        return this.httpService.get('/site-assignation');
    }

    createLinkRequestRequest(command: LinkRequestCommand): Observable<any> {
        return this.httpService.post(`/site-assignation`, command);
    }

    deleteLinkRequestRequest(gatewayId: string): Observable<any> {
        return this.httpService.delete(`/site-assignation/${gatewayId}`);
    }
}
