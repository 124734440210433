import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppState } from '../../../shared/store/app-state';
import type { LinkRequest } from '../../models/LinkRequest';
import type { LinkRequestCommand } from '../../models/LinkRequestCommand';
import { LinkRequestsService } from '../../services/link-requests.service';
import { LinkRequestsActions } from '../actions/link-requests';

@Injectable()
export class LinkRequestsApi {
    constructor(
        private store: Store<AppState>,
        private linkRequestService: LinkRequestsService,
    ) {}

    public getLinkRequests(): Observable<LinkRequest[]> {
        return this.linkRequestService.getAllLinkRequestPendingRequest().pipe(
            tap((pendingRequests: LinkRequest[]) => {
                this.store.dispatch(LinkRequestsActions.loadLinkRequests({ linkRequests: pendingRequests }));
            }),
        );
    }

    public createLinkRequestRequest(
        linkRequestCommand: LinkRequestCommand,
        remindingCustomer = false,
    ): Observable<any> {
        // so far we do nothing we the store, but it may change later
        return this.linkRequestService.createLinkRequestRequest(linkRequestCommand).pipe(
            tap(() => {
                if (!remindingCustomer) {
                    const linkRequest: LinkRequest = {
                        endUserEmail: linkRequestCommand.endUserEmail,
                        gatewayId: linkRequestCommand.gatewayId,
                        requestTimestamp: Date.now(),
                    };
                    this.store.dispatch(LinkRequestsActions.addLinkRequest({ linkRequest }));
                }
            }),
        );
    }

    public deleteLinkRequestRequest(gatewayId: string): Observable<any> {
        // so far we do nothing we the store, but it may change later
        return this.linkRequestService.deleteLinkRequestRequest(gatewayId).pipe(
            tap(() => {
                this.store.dispatch(LinkRequestsActions.deleteLinkRequest({ gatewayId }));
            }),
        );
    }
}
