import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import type { LinkRequest } from '../../models/LinkRequest';
import { LinkRequestsActions } from '../actions/link-requests';

export interface LinkRequestsState {
    linkRequests: LinkRequest[];
}

export const LINK_REQUESTS_INITIAL_STATE: LinkRequestsState = {
    linkRequests: [],
};

export default createReducer(
    LINK_REQUESTS_INITIAL_STATE,

    on(
        LinkRequestsActions.loadLinkRequests,
        (state, { linkRequests }): LinkRequestsState => ({
            ...state,
            linkRequests: linkRequests,
        }),
    ),

    on(
        LinkRequestsActions.addLinkRequest,
        (state, { linkRequest }): LinkRequestsState => ({
            ...state,
            linkRequests: [...state.linkRequests, linkRequest],
        }),
    ),

    on(LinkRequestsActions.deleteLinkRequest, (state, { gatewayId }): LinkRequestsState => {
        const existingLinkRequest = state.linkRequests.find((d: LinkRequest) => d.gatewayId === gatewayId);
        if (!existingLinkRequest) {
            return state;
        }

        const linkRequests = state.linkRequests.filter((d) => d.gatewayId !== gatewayId);

        return { ...state, linkRequests: linkRequests };
    }),
);

export const getLinkRequestsState = createFeatureSelector<LinkRequestsState>('link-requests');
