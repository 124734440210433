import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
    MAT_DIALOG_DATA,
    MatDialogActions,
    MatDialogContent,
    MatDialogRef,
    MatDialogTitle,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, timeout } from 'rxjs/operators';
import { LinkRequestsApi } from '../../store/api/link-requests-api';
import { collectErrors } from '../../../utils';
import { ToasterMessageType, ToasterSuccessMessage } from '../../../shared/models/Toaster';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ErrorService } from '../../../shared/services/error.service';
import { addToasterMessage } from '../../../shared/store/actions/addToasterMessage';
import { AppState } from '../../../shared/store/app-state';
import type { LinkRequestOption } from './LinkRequestOption';

enum LinkRequestErrorCodes {
    ALREADY_EXISTS = 'already_exists',
    ALREADY_LINKED = 'already_linked',
    COUNTRY_LINK_UNAUTHORIZED = 'country_link_unauthorized',
    WRONG_ASSOCIATION = 'wrong_association',
}

const linkRequestErrorToMessage = {
    [LinkRequestErrorCodes.ALREADY_EXISTS]: 'MODAL_ATTACH_GATEWAY_ALREADY_EXISTS',
    [LinkRequestErrorCodes.ALREADY_LINKED]: 'MODAL_ATTACH_GATEWAY_ALREADY_LINKED',
    [LinkRequestErrorCodes.WRONG_ASSOCIATION]: 'MODAL_ATTACH_GATEWAY_WRONG_ASSOCIATION',
    [LinkRequestErrorCodes.COUNTRY_LINK_UNAUTHORIZED]: 'MODAL_ATTACH_GATEWAY_COUNTRY_LINK_UNAUTHORIZED',
};

@Component({
    selector: 'app-link-request-modal',
    templateUrl: './modal-link-request.component.html',
    styleUrls: ['./modal-link-request.component.scss'],
    imports: [
        MatDialogTitle,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        NgClass,
        NgxMaskDirective,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ModalLinkRequestComponent {
    emailAndServegoOptionForm: any;
    formError: any;
    readonly = false;
    loading = false;
    attachRequestErrorCode?: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: LinkRequestOption,
        public dialogRef: MatDialogRef<ModalLinkRequestComponent>,
        private formBuilder: UntypedFormBuilder,
        private errorService: ErrorService,
        private linkRequestsApi: LinkRequestsApi,
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<AppState>,
        private analyticsService: AnalyticsService,
    ) {
        this.readonly = data.readonly;
        this.attachRequestErrorCode = undefined;

        this.emailAndServegoOptionForm = this.formBuilder.group({
            attachGatewayEmail: [data.enduserEmail, Validators.compose([Validators.required, Validators.email])],
            gatewayId: [data.gatewayId, Validators.required],
        });

        this.emailAndServegoOptionForm.valueChanges.subscribe(() => {
            this.formError = collectErrors(this.emailAndServegoOptionForm);
        });
    }

    public hide() {
        this.dialogRef.close();
    }

    public attachGateway(): void {
        this.loading = true;
        this.analyticsService.boxLinkingRequested();
        this.createLinkRequest(
            this.emailAndServegoOptionForm.value.gatewayId,
            this.emailAndServegoOptionForm.value.attachGatewayEmail,
        );
    }

    public cancelAttachGateway(): void {
        this.hide();
        this.emailAndServegoOptionForm.reset();
    }

    private createLinkRequest(gatewayId: string, endUserEmail: string) {
        return this.linkRequestsApi
            .createLinkRequestRequest({ endUserEmail, gatewayId }, this.readonly)
            .pipe(
                timeout(10 * 1000),
                catchError((err) => {
                    console.error(err);
                    this.attachRequestErrorCode = err?.error?.message as LinkRequestErrorCodes;
                    if (!this.attachRequestErrorCode) {
                        this.errorService.showToasterError('ERROR_WHILE_SENDING_SITE_ASSIGNATION_REQUEST');
                    }

                    this.loading = false;
                    this.changeDetectorRef.detectChanges();
                    return throwError(err);
                }),
            )
            .subscribe(() =>
                this.successCallback(
                    'CMS_SITE_ASSIGNATION_TOASTER_SUCCESS_TITLE',
                    'CMS_SITE_ASSIGNATION_TOASTER_SUCCESS_MESSAGE',
                ),
            );
    }

    private successCallback(toasterTitle: string, toasterMessage: string): void {
        const successMessage: ToasterSuccessMessage = {
            id: '',
            content: {
                successMessage: toasterMessage,
                title: toasterTitle,
            },
            type: ToasterMessageType.CONFIRM_SUCCESS,
        };

        this.store.dispatch(addToasterMessage({ message: successMessage }));
        this.loading = false;
        this.hide();
        this.changeDetectorRef.detectChanges();
    }

    generateLocalizedErrorKey(): string {
        return linkRequestErrorToMessage[this.attachRequestErrorCode] || 'MODAL_ATTACH_GATEWAY_UNKNOWN_ERROR';
    }
}
