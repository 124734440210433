import { createActionGroup, emptyProps, props } from '@ngrx/store';
import type { LinkRequest } from '../../models/LinkRequest';

export const LinkRequestsActions = createActionGroup({
    source: 'LinkRequests',
    events: {
        loadLinkRequests: props<{ linkRequests: LinkRequest[] }>(),
        addLinkRequest: props<{ linkRequest: LinkRequest }>(),
        deleteLinkRequest: props<{ gatewayId: string }>(),
    },
});

export const LinkRequestsPageActions = createActionGroup({
    source: 'Link Requests Page',
    events: {
        opened: emptyProps(),
    },
});
